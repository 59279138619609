<script>
  import { link } from "svelte-spa-router";
  import { cart } from "../../store";

  $: countOfItems = $cart.reduce((count) => {
    return count + 1;
  }, 0);
</script>

<header class="header">
  <div class="container">
    <div class="header__info">
      <a class="header__logo" href="/" use:link>
        <img alt="" src="/static/images/logo.svg" />
      </a>
      <div class="header__actions">
        <a class="header__cart" href="/checkout" use:link>
          <img alt="Cart icon" src="/static/images/cart.svg" />
          <span class="header__cart-count">{countOfItems}</span>
        </a>
      </div>
    </div>
  </div>
</header>

<style lang="scss">
  @import "./styles/mixins";

  .header {
    padding: 1vw 1vw;
    margin-bottom: 16px;

    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__cart {
      min-width: 3rem;
      height: 3rem;
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      line-height: 1;
      text-decoration: none;
      justify-content: center;
      position: relative;

      img {
        width: 28px;
      }

      &-count {
        color: #ffffff;
        min-width: 1.125rem;
        height: 1.125rem;
        line-height: 1.125rem;
        font-size: 0.75rem;
        font-weight: 700;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right: 0;
        background-color: #1b2738;
      }
    }

    &__logo {
      display: flex;
      text-decoration: none;
    }

    &__catalog {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
</style>
