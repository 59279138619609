<script>
  import Notifications from "svelte-notifications";
  import Header from "./components/common/Header.svelte";
  import Footer from "./components/common/Footer.svelte";
  import { onMount } from "svelte";
  import routes from "./routes";
  import Router from "svelte-spa-router";
  import { BASE_URL, DEFAULT_MERCHANT_ID } from "./const/common";
  import { merchantList, merchantData } from "./store";

  let pageLoaded = false;

  onMount(async () => {
    pageLoaded = true;

    try {
      const response = await fetch(`${BASE_URL}demo-helper/merchant/list`);
      if (response.status === 200) {
        const data = await response.json();
        merchantList.set(data);
        let currentMerchant;
        const defaultMerchant = data.find(
          (merchant) => merchant.merchantId === DEFAULT_MERCHANT_ID
        );
        if (typeof defaultMerchant === "undefined") {
          const firstAvailableMerchant = data.find(
            (merchant) => merchant.merchantId && merchant.merchantKey
          );
          currentMerchant = firstAvailableMerchant;
        } else {
          currentMerchant = defaultMerchant;
        }
        merchantData.set(currentMerchant);
      } else {
        throw new Error(`response status - ${response.status}`);
      }
    } catch (e) {
      console.warn(e);
    }
  });
</script>

<Notifications zIndex="9999999">
  <main>
    <div class={pageLoaded ? "layout active" : "layout"}>
      <div class="layout__top">
        <Header />
        <div class="layout__inner">
          <div class="container">
            <section class="section">
              <div class="section__body">
                <Router {routes} />
              </div>
            </section>
          </div>
        </div>
      </div>
      <div class="layout__bottom">
        <Footer />
      </div>
    </div>
  </main>
</Notifications>

<style lang="scss">
  .layout {
    $layout: &;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    opacity: 0;
    transition: opacity ease-in-out 300ms;

    &.active {
      opacity: 1;
    }

    &__top {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      position: relative;
      z-index: 1;
    }

    &__inner {
      flex-grow: 1;
    }
  }
</style>
