<script>
  import ShopItem from "../components/shop/ShopItem.svelte";
  import PaymentMethod from "../components/widget/PaymentMethod.svelte";

  import { products } from "../store";
</script>

<div class="shop">
  <div class="shop__header">
    <div class="shop__header-title">
      <h1>Тестовый магазин Romb</h1>
    </div>
    <p>
      Здесь вы можете пройти путь покупателя и посмотреть, как выглядит процесс
      оплаты с помощью Romb.
    </p>
  </div>
  <div class="shop__payment-method">
    <PaymentMethod />
  </div>
  <div class="shop__list">
    {#each $products as product}
      <div class="product-preview">
        <ShopItem {product} />
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  @import "../styles/_mixins.scss";

  .shop {
    margin-bottom: 1rem;

    &__header {
      margin-bottom: 1rem;

      &-title {
        margin-bottom: 1rem;
      }
    }

    &__list {
      display: grid;
      align-items: stretch;
      grid-template-columns: repeat(auto-fill, minmax(min(250px, 100%), 1fr));
      grid-template-rows: auto;
      grid-row-gap: 2rem;
      grid-column-gap: 3rem;
      @include md {
        grid-template-columns: repeat(auto-fill, minmax(min(160px, 100%), 1fr));
        grid-column-gap: 1.5rem;
      }
    }

    &__header + &__payment-method, &__payment-method + &__banner {
      margin-top: 32px;
    }

    &__payment-method + &__list {
      margin-top: 52px;
    }
  }
</style>
