<script>
  import { merchantData, merchantList } from "../../store";

  function changePaymentMethod(paymentMethod) {
    merchantData.set(paymentMethod);
  }
</script>

<div class="payment-method">
  <div class="payment-method__title">Выберите провайдера:</div>
  <div class="payment-method__list">
    {#each Object.entries($merchantList) as [key, payment]}
      <div
        class="payment-method__list-item"
        class:active={payment?.merchantId === $merchantData?.merchantId}
        on:click={() => changePaymentMethod(payment)}
      >
        {payment.companyName}
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  @import "../../styles/_mixins.scss";

  .payment-method {
    display: flex;
    align-items: center;

    @include lg {
      flex-direction: column;
      align-items: flex-start;
    }

    &__list {
      display: flex;
      align-items: center;
      border-radius: 7px;
      border: 1px solid var(--color-theme-main);
      overflow: hidden;

      @include lg {
        border-radius: 4px;
        overflow: initial;
        border-color: transparent;
        flex-wrap: wrap;
      }

      &-item {
        padding: 8px 16px;
        cursor: pointer;
        transition: ease-in-out background-color 200ms, ease-in-out color 200ms;

        &.active {
          background-color: var(--color-theme-main);
          color: var(--color-white);
        }

        @include lg {
            margin: 6px;
            border: 1px solid var(--color-theme-main);
            border-radius: 4px;
          }

        & + & {
          border-left: 1px solid var(--color-theme-main);
        }

        @include lg {
          font-size: 14px;
          padding: 4px 8px;
        }
      }
    }

    &__title + &__list {
      margin-left: 18px;

      @include lg {
        margin: 0 -6px;
      }
    }
  }
</style>
