<script>
  import CheckoutItem from "./CheckoutItem.svelte";

  import { cart } from "../../store";
</script>

<div class="checkout-list">
  <div class="checkout-list__list">
    {#each $cart as product (product.label)}
      <div class="checkout-list__list-item">
        <CheckoutItem {product} />
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  .checkout-list {
    background-color: var(--color-white);

    &__list {
      &-item {
        & + & {
          margin-top: 24px;
          padding-top: 24px;
          border-top: 1px solid var(--color-grey-border);
        }
      }
    }
  }
</style>
