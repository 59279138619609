<script>
  import { getNotificationsContext } from "svelte-notifications";
  import { cart } from "../../store";
  import Button from "../controls/Button.svelte";

  export let product;

  const { addNotification } = getNotificationsContext();

  function updateCart(product) {
    let updatedCart = $cart.map((cartItem) => {
      if (cartItem.label === product.label) {
        return product;
      }

      return cartItem;
    });
    cart.set(updatedCart);
  }

  function addToCart() {
    let quantity = 1;
    const isProductAlreadyInCart = $cart.find(
      (item) => item.label === product.label
    );
    if (isProductAlreadyInCart) {
      quantity = isProductAlreadyInCart.quantity + 1;
      product = Object.assign({}, product, {
        quantity,
        amount: quantity * product.price,
      });
      updateCart(product);
    } else {
      let updatedCart = [...$cart, { ...product, quantity }];
      cart.set(updatedCart);
    }

    addNotification({
      text: "Товар добавлен в корзину",
      position: "top-right",
      type: "success",
      removeAfter: 1000,
    });
  }
</script>

<div class="product-preview__content">
  <div class="product-preview__photo">
    <img alt={product.name} src="/static/images/{product.image}" />
  </div>
  <div class="product-preview__title">
    <p>{product.label}</p>
  </div>
  <div class="product-preview__bottom">
    <div class="product-preview__price">
      <p class="product-preview__price-cur">{product.price} ₽</p>
    </div>
    <div class="product-preview__controls">
      <Button
        color="theme"
        on:click={() => addToCart()}
        size="lg"
        text="Добавить в корзину"
        type="action"
      />
    </div>
  </div>
</div>

<style lang="scss">
  .product-preview {
    &__content {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: repeat(3, max-content) 1fr;
      line-height: 1.25;
      height: 100%;

      &:hover {
        .product-preview__controls {
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

    &__photo {
      min-width: 0;
      position: relative;
      margin: 0 0 1rem 0;

      img {
        height: 100%;
        width: 100%;
        max-width: none;
        max-height: none;
        object-position: 50% 50%;
        object-fit: contain;
      }
    }

    &__title {
      margin-bottom: 0.5rem;
      font-size: 1.125rem;
      word-break: break-word;
    }

    &__description {
      color: #999999;
      font-size: 0.875rem;
      margin-bottom: 0.5rem;
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__price {
      margin-bottom: 0.5rem;
    }

    &__controls {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      transition: 0.2s;
    }
  }
</style>
