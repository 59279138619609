<script>
  import MenuBottom from "./MenuBottom.svelte";
</script>

<footer class="footer">
  <div class="container">
    <div class="footer__inner">
      <div class="footer__info">
        <a class="footer__logo" href="/">
          <img alt="" src="/static/images/logo.svg" />
        </a>
      </div>
      <div class="footer__menu">
        <MenuBottom />
      </div>
    </div>
  </div>
</footer>

<style lang="scss">
  @import "../../styles/_mixins.scss";

  .footer {
    padding: 42px 0 30px;
    background: #e7eaed;
    mix-blend-mode: multiply;

    &__inner {
      display: grid;
      grid-template:
        "logo menu menu menu"
        "logo menu menu menu";
      grid-gap: 1rem;

      @include md {
        grid-template:
          "logo logo logo"
          "menu menu menu";
      }
    }

    &__info {
      flex-grow: 1;
      padding-top: 8px;
      grid-area: logo;
    }

    &__menu {
      flex-grow: 3;
      grid-area: menu;
    }

    &__logo {
      display: flex;
      text-decoration: none;
    }
  }
</style>
