import {writable} from "svelte/store";

export const products = writable([
    {
        label: "Phone",
        description: "Идеальный",
        quantity: 1,
        price: 86000,
        amount: 86000,
        vat: "10",
        image: "product-1.png",
        measurementUnit: "QT",
        vendorCode: "1234",
        category: "",
        length: 100,
        width: 101,
        height: 102,
    },
    {
        label: "Tablet",
        description: "Восхитительный",
        quantity: 1,
        price: 120000,
        amount: 120000,
        vat: "10",
        image: "product-2.png",
        measurementUnit: "QT",
        vendorCode: "1235",
        category: "",
        length: 103,
        width: 104,
        height: 105,
    },
    {
        label: "Monitor",
        description: "Драгоценный",
        quantity: 1,
        price: 80000,
        amount: 80000,
        vat: "10",
        image: "product-3.png",
        measurementUnit: "QT",
        vendorCode: "1236",
        category: "",
        length: 106,
        width: 107,
        height: 108,
    },
    {
        label: "Comp Station",
        description: "Шикарный",
        quantity: 1,
        price: 1280,
        amount: 1280,
        vat: "10",
        image: "product-4.png",
        measurementUnit: "QT",
        vendorCode: "1237",
        category: "",
        length: 109,
        width: 110,
        height: 111,
    },
    {
        label: "Headphones",
        description: "Великолепный",
        quantity: 1,
        price: 4,
        amount: 4,
        vat: "10",
        image: "product-5.png",
        measurementUnit: "QT",
        vendorCode: "1238",
        category: "",
        length: 109,
        width: 110,
        height: 111,
    },
    {
        label: "Photo",
        description: "Редкий",
        quantity: 1,
        price: 2,
        amount: 2,
        vat: "10",
        image: "product-6.png",
        measurementUnit: "QT",
        vendorCode: "1239",
        category: "",
        length: 109,
        width: 110,
        height: 111,
    },
]);

const storedCart = JSON.parse(localStorage.getItem('cart'));

export const cart = writable(storedCart || []);
cart.subscribe((value) => localStorage.cart = JSON.stringify(value))

export const merchantData = writable({});
export const externalJWT = writable('');

export const merchantList = writable([]);
