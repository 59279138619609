<script>
  import { cart } from "../../store";

  export let product = {};

  let itemQuantity;

  $: {
    let get_product = $cart.find((item) => item.label === product.label);
    itemQuantity = get_product.quantity;
  }

  function changeQuantity(action = "add") {
    if (action === "add") {
      const newQuantity = product.quantity + 1;
      product = Object.assign({}, product, {
        quantity: newQuantity,
        amount: newQuantity * product.price,
      });
      updateCart(product);
    } else {
      const newQuantity = product.quantity - 1;
      if (product.quantity > 1) {
        product = Object.assign({}, product, {
          quantity: newQuantity,
          amount: newQuantity * product.price,
        });
        updateCart(product);
      } else {
        removeItem(product);
      }
    }
  }

  function removeItem(product) {
    let removedItemCart = $cart.filter((cartItem) => {
      return cartItem.label !== product.label;
    });

    cart.set(removedItemCart);
  }

  function updateCart(product) {
    let updatedCart = $cart.map((cartItem) => {
      if (cartItem.label === product.label) {
        return product;
      }

      return cartItem;
    });

    cart.set(updatedCart);
  }
</script>

<div class="checkout-item">
  <div class="checkout-item__image">
    <img alt="{product.label} logo" src="/static/images/{product.image}" />
  </div>
  <div class="checkout-item__name">{product.label}</div>
  <div class="checkout-item__description">{product.description}</div>
  <div class="checkout-item__price">{product.price} ₽/шт</div>
  <div class="checkout-item__counter">
    <button
      class="counter-button"
      on:click={() => changeQuantity("remove")}
      type="button"
      >-
    </button>
    <input bind:value={product.quantity} class="counter-input" type="text" />
    <button
      class="counter-button"
      on:click={() => changeQuantity()}
      type="button"
      >+
    </button>
  </div>
  <div class="checkout-item__total">
    {product.amount} ₽
  </div>
  <div class="checkout-item__remove">
    <img
      alt="Remove from cart icon"
      on:click={() => removeItem(product)}
      src="/static/images/remove.svg"
    />
  </div>
</div>

<style lang="scss">
  @import "../../styles/_mixins.scss";

  .checkout-item {
    display: grid;
    grid-template-areas:
      "image label counter total delete"
      "image description . . ."
      "image price . . .";
    grid-auto-columns: 120px 1fr min-content auto 1.5rem;
    gap: 0.25rem 2rem;
    padding-bottom: 1.5rem;

    &__image {
      width: 104px;
      height: 104px;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-area: image;

      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__name {
      font-size: 16px;
      line-height: 24px;
      grid-area: label;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
      color: var(--color-grey-text);
      grid-area: description;
    }

    &__price {
      grid-area: price;
    }

    &__counter {
      grid-area: counter;
      display: flex;
      height: 30px;

      .counter-button {
        padding: 7px;
        font-size: 1rem;
        line-height: 0;
        color: #333333;
        background: #f7f7f7;
        cursor: pointer;
        border: 1px solid transparent;
      }

      .counter-input {
        border: 0;
        color: #333333;
        text-align: center;
        background: #f7f7f7;
        border-radius: 0;
        width: 4rem;
        font-size: 1.1rem;
        outline: none;
        box-shadow: none;
      }
    }

    &__total {
      grid-area: total;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    &__remove {
      align-self: flex-start;
      grid-area: delete;
      cursor: pointer;

      img {
        width: 30px;
      }
    }

    @include sm {
      grid-template-areas:
        "image label delete"
        "image description delete"
        "image total delete"
        "image price delete"
        "image counter delete";
      grid-auto-columns: 130px auto 1.5rem;
      gap: 0.25rem 1rem;
      padding: 1.5rem 0;

      &__image {
        width: auto;
        height: auto;
      }
    }
  }
</style>
