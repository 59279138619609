<script>
  export let type = "default";
  export let size = "md";
  export let color = "grey";
  export let text;
  export let state = "enabled";
  export let icon = null;

  let buttonClass = `button button--size-${size} button--color-${color} button--type-${type} button--state-${state}`;

  if (icon) buttonClass += " button--icon";
</script>

{#if type === "default"}
  <a href={"#"} class={buttonClass} on:click>
    {text}
    {#if icon}
      <img src="/static/images/{icon}" alt="" />
    {/if}
  </a>
{:else}
  <button class={buttonClass} on:click>
    {text}
    {#if icon}
      <img src="/static/images/{icon}" alt="" />
    {/if}
  </button>
{/if}

<style lang="scss">
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 8px 20px;
    border-radius: 4px;
    box-shadow: none;
    border: none;
    cursor: pointer;
    transition: opacity ease-in-out 300ms;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }

    &--color {
      &-grey {
        background-color: var(--color-grey-dark);
        color: var(--color-white);
      }

      &-theme {
        background-color: var(--color-theme-main);
        color: var(--color-white);
      }
    }

    &--state {
      &-disabled {
        background-color: var(--color-grey-dark);
        cursor: not-allowed;
      }
    }

    &--size {
      &-lg {
        padding: 14px 20px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &--icon {
      img {
        margin-left: 8px;
      }
    }
  }
</style>
