<script>
  import CheckoutList from "../components/checkout/CheckoutList.svelte";
  import CheckoutOrder from "../components/checkout/CheckoutOrder.svelte";
</script>

<div class="checkout">
  <div class="checkout__list">
    <a class="checkout__back" href="/#">Назад</a>
    <h2 class="checkout__title">Корзина</h2>
    <CheckoutList />
  </div>
  <div class="checkout__order">
    <CheckoutOrder />
  </div>
</div>

<style lang="scss">
  @import "../styles/_mixins.scss";

  .checkout {
    display: grid;
    grid-template-areas:
      "list order"
      "list order"
      "list order";
    grid-gap: 2rem;
    grid-auto-columns: 4fr 2fr;
    grid-auto-rows: max-content auto;

    &__back {
      cursor: pointer;
      color: #333333;
      border: 0;
      text-decoration: none;
      display: block;
      margin-bottom: 24px;

      &:before {
        content: "";
        width: 24px;
        display: inline-block;
        height: 12px;
        background: url(/static/images/back.svg) no-repeat center;
      }
    }

    &__title {
      margin-bottom: 32px;
    }

    &__list {
      grid-area: list;
    }

    &__order {
      grid-area: order;
    }

    @include lg {
      grid-template-areas:
        "list"
        "order";
      grid-gap: 1rem;
    }
  }
</style>
