<script>
  import { externalJWT } from "../../store";

  const jwt = 'eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2Nzc2ODY5NDQsImV4cCI6MTcwOTIyMjk0NCwicGhvbmUiOiIrNzk1MzY1NDg3OTgiLCJuYW1lIjoiVGVzdE5hbWUiLCJlbWFpbCI6InRlc3RAbmFtZS5jb20ifQ.G6cfh5skOQMMVF8Hlryu5h9gScs2Iu_6iHP-BfH_L3A';

  function toggleExtertnalJWT(e) {
    e.preventDefault();

    if ($externalJWT) {
      externalJWT.set('')
    } else {
      externalJWT.set(jwt)
    }
  }
</script>

<div class="menu-bottom">
  <div class="menu-bottom__item">
    <div class="menu-bottom__item-title">О компании</div>
    <a class="menu-bottom__item-value" href="/">Вакансии</a>
    <a class="menu-bottom__item-value" href="/">Миссия</a>
    <a class="menu-bottom__item-value" href="/">Реквизиты</a>
  </div>
  <div class="menu-bottom__item">
    <div class="menu-bottom__item-title">Помощь</div>
    <a class="menu-bottom__item-value" href="/">Как сделать заказ</a>
    <a class="menu-bottom__item-value" href="/">Доставка</a>
    <a class="menu-bottom__item-value" href="/">Оплата</a>
    <a class="menu-bottom__item-value" href="/">Контакты</a>
  </div>
  <div class="menu-bottom__item">
    <div class="menu-bottom__item-title">Developers</div>
    <a class="menu-bottom__item-value" href="/">Documentation</a>
    <a class="menu-bottom__item-value" href="/">API</a>
    <a class="menu-bottom__item-value" href="/">Careers</a>
    <a class="menu-bottom__item-value" href="/" on:click={(e) => toggleExtertnalJWT(e)}>{!!$externalJWT ? 'Выключить' : 'Включить' } внешнюю авторизацию</a>
  </div>
</div>

<style lang="scss">
  @import "../../styles/_mixins.scss";

  .menu-bottom {
    display: flex;
    font-size: 18px;
    line-height: 32px;

    @include sm {
      flex-direction: column;
    }

    &__item {
      display: flex;
      flex-direction: column;
      flex-basis: auto;
      flex-grow: 1;

      &-title {
        font-weight: 600;
        color: var(--color-black-medium);
      }

      &-value {
        text-decoration: none;
        cursor: pointer;
        color: var(--color-black-light);
        letter-spacing: 0.75px;
        transition: color ease-in-out 200ms;

        &:hover {
          color: var(--color-theme-main);
        }
      }

      &-title + &-value {
        margin-top: 15px;
      }
    }
  }
</style>
