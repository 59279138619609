<script>
  export let show = false;

  function modalClose() {
    show = false;
  }
</script>

<div class={show ? "modal active" : "modal"}>
  <div class="modal__overlay" on:click={modalClose} />
  <div class="modal__inner">
    <div class="modal__content">
      <slot name="content" />
    </div>
  </div>
</div>

<style lang="scss">
  .modal {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity ease-in-out 300ms, transform ease-in-out 300ms;

    &.active {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0);
    }

    &__overlay {
      z-index: 999;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00000025;
    }

    &__inner {
      z-index: 1001;
      position: relative;
      border-radius: 6px;
      background-color: var(--color-white);

      @media (max-width: 640px) {
        width: 100%;
        height: 100%;
      }
    }

    &__content {
      @media (max-width: 640px) {
        overflow-y: auto;
        width: 100%;
        height: 100%;
        & > div {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>
