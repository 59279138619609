<script>
  import Button from "../controls/Button.svelte";
  import Modal from "../modals/Modal.svelte";
  import { cart, merchantData, externalJWT } from "../../store";
  import { getNotificationsContext } from "svelte-notifications";
  import { getRandomInteger } from "../../utils/getRandomInteger";

  $: amount = $cart.reduce((total, next) => {
    return total + next.quantity * next.price;
  }, 0);

  $: cartToCheckout = $cart.map((cartItem) =>
    // one-liner from to remove image & description fields
    // eslint-disable-next-line no-unused-vars
    (({ image, description, ...o }) => o)(cartItem)
  );

  $: countOfItems = $cart.reduce((count, product) => {
    return count + product.quantity;
  }, 0);

  $: isEmptyBasket = countOfItems === 0;

  $: buttonState = isEmptyBasket ? "disabled" : "enabled";

  let showCheckoutWidget = false;
  let checkoutWidgetCreated = false;

  const { addNotification } = getNotificationsContext();

  function openCheckoutWidget() {
    if (countOfItems === 0) {
      addNotification({
        text: "Ваша корзина пуста",
        position: "top-right",
        type: "warning",
        removeAfter: 1000,
      });
    } else {
      console.log($externalJWT)
      if (!checkoutWidgetCreated) {
        // eslint-disable-next-line no-undef
        CheckoutWidget.init({
          container: document.getElementById("checkout-widget"),
          data: {
            order: {
              merchantOrderId: randomMerchantOrderId,
              amount,
              currency: "RUB",
              products: cartToCheckout,
            },
            merchantId: $merchantData.merchantId,
            merchantKey: $merchantData.merchantKey,
            externalJwt: $externalJWT || null
          },
          onClose: closeHandler,
        });
      }
      checkoutWidgetCreated = true;
      showCheckoutWidget = true;
    }
  }

  function closeHandler() {
    showCheckoutWidget = false;
  }

  const randomMerchantOrderId = getRandomInteger(1, 1000000);
</script>

<div class="checkout-order">
  <div class="checkout-order__action">
    <div class="checkout-order__action-text">
      Доступные способы и время доставки можно выбрать при оформлении заказа
    </div>
  </div>
  <div class="checkout-order__list">
    <div class="checkout-order__list-item">
      <div class="checkout-order__list-item-title">
        Товары ({countOfItems})
      </div>
      <div class="checkout-order__list-item-value">
        {amount} ₽
      </div>
    </div>
  </div>
  <div class="checkout-order__total">
    <div class="checkout-order__total-title">Итого:</div>
    <div class="checkout-order__total-value">
      {amount} ₽
    </div>
  </div>
  <div class="checkout-order__action-submit" on:click={openCheckoutWidget}>
    <Button
      color="theme"
      icon="romb.svg"
      size="lg"
      state={buttonState}
      text="Оформить заказ через romb"
      type="action"
    />
  </div>
</div>
<Modal bind:show={showCheckoutWidget}>
  <div id="checkout-widget" slot="content">widget</div>
</Modal>

<style lang="scss">
  .checkout-order {
    border-radius: 4px;
    background-color: var(--color-white);

    & > * + * {
      margin-top: 15px;
      padding-top: 28px;
      border-top: 1px solid var(--color-grey-border);
    }

    &__action {
      &-text {
        font-size: 14px;
        line-height: 20px;
        color: var(--color-grey-text);
      }

      &-submit + &-text {
        margin-top: 18px;
      }
    }

    &__list {
      &-item {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &-value {
          font-weight: 600;
        }

        & + & {
          margin-top: 2px;
        }
      }

      &-title + &-item {
        margin-top: 10px;
      }
    }

    &__total {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      &-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }

      &-value {
        font-weight: 600;
        font-size: 22px;
        line-height: 24px;
      }
    }
  }
</style>
